/* 系统字体 */
@font-face {
  font-family: Noto Sans TC;
  src: url('./font/NotoSansTC-Regular.otf');
}

@font-face {
  font-family: Noto Sans SC;
  src: url('./font/NotoSansSC-Regular.otf');
}

@font-face {
  font-family: Msyh;
  src: url('./font/Msyh.ttc');
}

@font-face {
  font-family:Simhei;
  src: url('./font/simhei.ttf');
}
